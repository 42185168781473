import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const IndexPage = () => {
  return (
    <main  className="bg-white" style={pageStyles}>
        <Layout>
            <section className="flex items-center h-auto py-20 justify-center bg-white">
                <div className="mx-auto max-w-[43rem]">
                    <div className="text-center">
                        <h1 className="text-[2.5rem] font-bold leading-[3rem] tracking-tight text-black">
                            Privacy Policy
                        </h1>
                        <p className="mt-2 text-md leading-relaxed text-slate-400 text-left">
                            The privacy policy of a web page is a legal document in which the owner of the website must inform its clients and users about the personal 
                            data that is collected when browsing the site, through which means this data is collected,stored and on the treatment that will be carried out on them.
                            <br /><br />
                            The privacy policy must also include the measures used to guarantee the security and legal use of the personal data collected on the web, that is, to guarantee the privacy of the data.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    </main>     
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Privacy Policy!</title>

